import {
  combine,
  createStore,
  createEvent,
  createEffect,
  sample,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import { getErrors } from 'src/effector/forms/helpers';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import first from 'lodash/first';
import * as api from 'src/logic/api';

const steps = {
  login: 'login',
  signup: 'signup',
  confirm: 'confirm',
  accountType: 'accountType',
  resetPassword: 'resetPassword',
  resetPasswordInfo: 'resetPasswordInfo',

  proprietor1: 'personalInfo',
  proprietor2: 'personalLocation',
  proprietor3: 'personalProfession',
  proprietor4: 'personalSalary',

  business1: 'businessInfo',
  business2: 'businessLocation',
  business3: 'businessDetails',
  business4: 'businessCompany',

  completed: 'completed',
};

const setData = createEvent();
const setCurrentStep = createEvent();
const clearApiMessage = createEvent();
const login = createEvent();
const loginSubmit = createEvent();
const resetPassword = createEvent();
const resetPasswordSubmit = createEvent();
const resendResetPassword = createEvent();
const createAccount = createEvent();
const createAccountSubmit = createEvent();
const addAccountType = createEvent();
const addAccountTypeSubmit = createEvent();
const resendConfirmation = createEvent();
const addAdditionalInfoProprietor = createEvent();
const addAdditionalInfoProprietorSubmit = createEvent();
const addAdditionalInfoBusinessSubmit = createEvent();
const addAdditionalInfoBusiness = createEvent();
const redirectToSignup = createEvent();

const loginFx = createEffect().use(api.login);
const resetPasswordFx = createEffect().use(api.resetPassword);
const resendResetPasswordFx = createEffect().use(api.resetPassword);
const createAccountFx = createEffect().use(api.createAccount);
const addAccountTypeFx = createEffect().use(api.updateAccount);
const addAdditionalInfoFx = createEffect().use(api.updateAccount);
const resendConfirmationFx = createEffect().use(api.resendConfirmation);
const validateEmailFx = createEffect().use(api.validateEmail);

const $emailValidationMessage = createStore('');
const $currentEmail = createStore('')
  .on(setData, (_, data) => data?.user?.email)
  .on(loginFx.done, (_, data) => data?.result?.user?.email)
  .on(resetPasswordFx.done, (_, data) => data?.result?.user?.email);

const $roles = createStore({}).on(setData, (_, data) =>
  keyBy(data.roles, 'name'),
);

const $currentRole = createStore('')
  .on(setData, (_, data) => first(data?.user?.spree_roles))
  .on(loginFx.done, (_, data) => first(data?.result?.user?.spree_roles))
  .on(addAccountTypeFx.done, (_, data) =>
    first(data?.result?.user?.spree_roles),
  );

const $states = createStore([]).on(setData, (_, data) => data.states);
const $professions = createStore([]).on(setData, (_, data) => data.professions);
const $payFrequencies = createStore([]).on(
  setData,
  (_, data) => data.payFrequencies,
);
const $businessTypes = createStore([]).on(
  setData,
  (_, data) => data.businessTypes,
);
const $referralSource = createStore([]).on(
  setData,
  (_, data) => data.referralSource,
);
const $entityTypes = createStore([]).on(setData, (_, data) => data.entityTypes);
const $industries = createStore([]).on(setData, (_, data) => data.industries);

sample({
  clock: setData,
  fn: (_, data) => {
    console.log('setData', data);
  },
});

const getCurrentStep = (currentStep, data) => {
  if (data.status === 'fail') return currentStep;
  const user = data?.result?.user || data?.user;
  if (!user) return steps.signup;
  if (!user?.confirmed) return steps.confirm;
  if (user?.spree_roles.length === 0) return steps.accountType;
  if (user?.additional_info == null) {
    const step =
      first(user?.spree_roles)?.name === 'proprietor'
        ? steps.proprietor1
        : steps.business1;
    return step;
  }
  return steps.completed;
};

const $currentStep = createStore(steps.login)
  .on(setCurrentStep, (_, step) => step)
  .on(setData, (_, data) =>
    includes(data.path, 'fleet_login') ? steps.login : getCurrentStep(_, data),
  )
  .on(loginFx.finally, (currentStep, data) => getCurrentStep(currentStep, data))
  .on(resetPasswordFx.done, () => steps.resetPasswordInfo)
  .on(createAccountFx.done, () => steps.confirm)
  .on(addAccountTypeFx.done, (currentStep, data) =>
    getCurrentStep(currentStep, data),
  )
  .on(addAdditionalInfoFx.done, (currentStep, data) =>
    getCurrentStep(currentStep, data),
  );

sample({
  source: $currentStep,
  filter: step => step === steps.completed,
  target: createEffect().use(() => (window.location = '/')),
});

sample({
  clock: loginFx.finally,
  filter: data => {
    return (
      data?.result &&
      data?.params?.formId === 'indexLoginForm' &&
      data?.result?.user?.confirmed
    );
  },
  target: createEffect().use(() => (window.location = '/fleet_signup')),
});

const $isLoading = createStore(false)
  .on(
    [
      login,
      resetPassword,
      resendResetPassword,
      createAccount,
      addAccountType,
      resendConfirmation,
    ],
    () => true,
  )
  .on(
    [
      loginFx,
      resetPasswordFx.finally,
      createAccountFx.finally,
      addAccountTypeFx.finally,
      resendConfirmationFx.finally,
    ],
    () => false,
  );

const formatApiMessages = data => {
  console.log('apiMessage', data);
  const errorMessage = data?.error?.response?.data?.error;
  const successMessage = data?.result?.message;

  return {
    error: isArray(errorMessage)
      ? errorMessage.map(msg => `<span>${msg}</span>`).join('')
      : errorMessage?.trim(),
    success: successMessage?.trim(),
  };
};

const $apiMessage = createStore(null)
  .on(clearApiMessage, () => null)
  .on(loginFx.finally, (_, data) => formatApiMessages(data))
  .on(resetPasswordFx.finally, (_, data) => formatApiMessages(data))
  .on(createAccountFx.finally, (_, data) => formatApiMessages(data))
  .on(resendConfirmationFx.finally, (_, data) => formatApiMessages(data))
  .on(addAccountTypeFx.finally, (_, data) => formatApiMessages(data))
  .on(addAdditionalInfoFx.finally, (_, data) => formatApiMessages(data));

const loginForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
    formId: {
      init: '',
    },
  },
});

const $loginFormErrors = getErrors(Object.keys(loginForm.fields), loginForm);
const $isValidLoginForm = loginForm.$isValid.map(isValid => isValid);
const $loginDataToSend = loginForm.$values.map(data => data);

sample({
  clock: sample({
    clock: loginSubmit,
    target: loginForm.validate,
  }),
  source: loginForm.$isValid,
  filter: isValid => isValid,
  target: login,
});

sample({
  clock: login,
  source: $loginDataToSend,
  target: loginFx,
});

const resetPasswordForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
});

const $resetPasswordFormErrors = getErrors(
  Object.keys(resetPasswordForm.fields),
  resetPasswordForm,
);
const $isValidResetPasswordForm = resetPasswordForm.$isValid.map(
  isValid => isValid,
);
const $resetPasswordDataToSend = resetPasswordForm.$values.map(data => data);

sample({
  clock: sample({
    clock: resetPasswordSubmit,
    target: resetPasswordForm.validate,
  }),
  source: resetPasswordForm.$isValid,
  filter: isValid => isValid,
  target: resetPassword,
});

sample({
  clock: resetPassword,
  source: $resetPasswordDataToSend,
  target: resetPasswordFx,
});

sample({
  clock: resendResetPassword,
  source: $currentEmail.map(email => ({ email })),
  target: resetPasswordFx,
});

const createAccountForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.email()],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
    password_confirmation: {
      init: '',
      rules: [rules.required()],
    },
    payment_type: {
      init: '',
      rules: [rules.required()],
    },
  },
});

const $createAccountFormErrors = getErrors(
  Object.keys(createAccountForm.fields),
  createAccountForm,
);
const $isValidCreateAccountForm = createAccountForm.$isValid.map(
  isValid => isValid,
);

const $createAccountDataToSend = createAccountForm.$values.map(data => data);

sample({
  clock: sample({
    clock: createAccountSubmit,
    target: createAccountForm.validate,
  }),
  source: createAccountForm.$isValid,
  filter: isValid => isValid,
  target: createAccount,
});

sample({
  clock: createAccount,
  source: $createAccountDataToSend,
  target: createAccountFx,
});

sample({
  clock: createAccountFx.done,
  fn: data => {
    return data.result.user.email;
  },
  target: $currentEmail,
});

sample({
  clock: createAccountForm.fields.email.onBlur,
  source: createAccountForm.fields.email.$value,
  filter: value => !isEmpty(value),
  fn: email => ({ email }),
  target: validateEmailFx,
});

sample({
  clock: loginForm.fields.email.onBlur,
  source: loginForm.fields.email.$value,
  filter: value => !isEmpty(value),
  fn: email => ({ email }),
  target: validateEmailFx,
});

sample({
  clock: resetPasswordForm.fields.email.onBlur,
  source: resetPasswordForm.fields.email.$value,
  filter: value => !isEmpty(value),
  fn: email => ({ email }),
  target: validateEmailFx,
});

sample({
  source: validateEmailFx.done,
  fn: data => {
    if (data.result.didYouMean)
      return `Did you mean to enter ${data.result.didYouMean}?`;
    if (data.result.status === 'invalid')
      return 'Please double check your email is correct.';
    return '';
  },
  target: $emailValidationMessage,
});

const addAccountTypeForm = createForm({
  fields: {
    role: {
      init: null,
      rules: [rules.required()],
    },
  },
});

const $addAccountTypeFormErrors = getErrors(
  Object.keys(addAccountTypeForm.fields),
  addAccountTypeForm,
);
const $isValidAddAccountTypeForm = addAccountTypeForm.$isValid.map(
  isValid => isValid,
);

const $addAccountTypeDataToSend = combine(
  {
    addAccountTypeFormData: addAccountTypeForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAccountTypeFormData, currentEmail }) => ({
    user: {
      spree_role_ids: [addAccountTypeFormData?.role?.id],
      email: currentEmail,
    },
  }),
);

sample({
  clock: sample({
    clock: addAccountTypeSubmit,
    target: addAccountTypeForm.validate,
  }),
  source: addAccountTypeForm.$isValid,
  filter: isValid => isValid,
  target: addAccountType,
});

sample({
  clock: addAccountType,
  source: $addAccountTypeDataToSend,
  target: addAccountTypeFx,
});

const addAdditionalInfoProprietorForm = createForm({
  fields: {
    firstName: {
      init: '',
      rules: [rules.required()],
    },
    lastName: {
      init: '',
      rules: [rules.required()],
    },
    company: {
      init: '',
      rules: [rules.required()],
    },
    vehicleNumber: {
      init: 0,
      rules: [rules.required()],
    },
    referralSource: {
      init: '',
      rules: [rules.required()],
    },
    streetAddress: {
      init: '',
      rules: [rules.required()],
    },
    streetAddress2: {
      init: '',
    },
    city: {
      init: '',
      rules: [rules.required()],
    },
    state_id: {
      init: '',
      rules: [rules.required()],
    },
    state_abbr: {
      init: '',
    },
    zipcode: {
      init: '',
      rules: [rules.required(), rules.minLength(5)],
    },
    profession: {
      init: '',
      rules: [rules.required()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.minLength(10)],
    },
    ssn_itin: {
      init: '',
      rules: [
        rules.required(),
        {
          name: 'ssn-format',
          validator: value =>
            /^[A-Za-z0-9]{3}-[A-Za-z0-9]{2}-[A-Za-z0-9]{4}$/.test(value),
          errorText: 'Invalid SSN/ITIN format (XXX-XX-XXXX)',
        },
      ],
    },
    ein: {
      init: '',
      rules: [
        rules.required(),
        {
          name: 'ein-format',
          validator: value => /^[0-9]{2}-[0-9]{7}$/.test(value),
          errorText: 'Invalid EIN format (XX-XXXXXXX)',
        },
      ],
    },
    grossMonthlyIncome: {
      init: '',
      rules: [rules.required()],
    },
    payFrequencyDays: {
      init: 0,
      rules: [rules.required()],
    },
    lastPaycheckDate: {
      init: null,
      rules: [rules.required()],
    },
  },
});

const $addAdditionalInfoProprietorFormErrors = getErrors(
  Object.keys(addAdditionalInfoProprietorForm.fields),
  addAdditionalInfoProprietorForm,
);

const $isValidAddAdditionalInfoProprietorForm = addAdditionalInfoProprietorForm.$isValid.map(
  isValid => {
    console.log('isValid', isValid);
    return isValid;
  },
);

const $addAdditionalInfoProprietorToSend = combine(
  {
    addAdditionalInfoFormData: addAdditionalInfoProprietorForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAdditionalInfoFormData, currentEmail }) => ({
    user: {
      additional_info: { ...addAdditionalInfoFormData },
      email: currentEmail,
    },
  }),
);

sample({
  clock: sample({
    clock: addAdditionalInfoProprietorSubmit,
    target: addAdditionalInfoProprietorForm.validate,
  }),
  source: addAdditionalInfoProprietorForm.$isValid,
  filter: isValid => isValid,
  target: addAdditionalInfoProprietor,
});

const addAdditionalInfoBusinessForm = createForm({
  fields: {
    companyName: {
      init: '',
      rules: [rules.required()],
    },
    dba: {
      init: '',
      rules: [rules.required()],
    },
    streetAddress: {
      init: '',
      rules: [rules.required()],
    },
    suiteOrFloor: {
      init: '',
    },
    city: {
      init: '',
      rules: [rules.required()],
    },
    state_id: {
      init: '',
      rules: [rules.required()],
    },
    state_abbr: {
      init: '',
    },
    zipcode: {
      init: '',
      rules: [rules.required(), rules.minLength(5)],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.minLength(10)],
    },
    totalAnnualRevenue: {
      init: '',
      rules: [rules.required()],
    },
    businessType: {
      init: '',
      rules: [rules.required()],
    },
    entityType: {
      init: '',
      rules: [rules.required()],
    },
    industry: {
      init: '',
      rules: [rules.required()],
    },
    ein: {
      init: '',
      rules: [
        rules.required(),
        {
          name: 'ein-format',
          validator: value => /^[0-9]{2}-[0-9]{7}$/.test(value),
          errorText: 'Invalid EIN format (XX-XXXXXXX)',
        },
      ],
    },
    dateEstablished: {
      init: null,
      rules: [rules.required()],
    },
    vehicleNumber: {
      init: 0,
      rules: [
        rules.required(),
        {
          name: 'non-negative',
          validator: value => Number.isInteger(Number(value)) && value >= 0,
          errorText: 'Vehicle number must be 0 or greater',
        },
      ],
    },
  },
});

const $addAdditionalInfoBusinessFormErrors = getErrors(
  Object.keys(addAdditionalInfoBusinessForm.fields),
  addAdditionalInfoBusinessForm,
);

const $isValidAddAdditionalInfoBusinessForm = addAdditionalInfoBusinessForm.$isValid.map(
  isValid => isValid,
);

const $addAdditionalInfoBusinessToSend = combine(
  {
    addAdditionalInfoFormData: addAdditionalInfoBusinessForm.$values,
    currentEmail: $currentEmail,
  },
  ({ addAdditionalInfoFormData, currentEmail }) => ({
    user: {
      additional_info: { ...addAdditionalInfoFormData },
      email: currentEmail,
    },
  }),
);

sample({
  clock: sample({
    clock: addAdditionalInfoBusinessSubmit,
    target: addAdditionalInfoBusinessForm.validate,
  }),
  source: addAdditionalInfoBusinessForm.$isValid,
  filter: isValid => isValid,
  target: addAdditionalInfoBusiness,
});

sample({
  clock: addAdditionalInfoProprietor,
  source: $addAdditionalInfoProprietorToSend,
  target: addAdditionalInfoFx,
});

sample({
  clock: addAdditionalInfoBusiness,
  source: $addAdditionalInfoBusinessToSend,
  target: addAdditionalInfoFx,
});

sample({
  clock: resendConfirmation,
  source: $currentEmail.map(email => ({ email })),
  target: resendConfirmationFx,
});

const $loginStep = $currentStep.map(step => step === steps.login);
const $signupStep = $currentStep.map(step => step === steps.signup);
const $confirmStep = $currentStep.map(step => step === steps.confirm);
const $accountTypeStep = $currentStep.map(step => step === steps.accountType);
const $resetPasswordStep = $currentStep.map(
  step => step === steps.resetPassword,
);
const $resetPasswordInfoStep = $currentStep.map(
  step => step === steps.resetPasswordInfo,
);

const $proprietor1Step = $currentStep.map(s => s === steps.proprietor1);
const $proprietor2Step = $currentStep.map(s => s === steps.proprietor2);
const $proprietor3Step = $currentStep.map(s => s === steps.proprietor3);
const $proprietor4Step = $currentStep.map(s => s === steps.proprietor4);

const $business1Step = $currentStep.map(s => s === steps.business1);
const $business2Step = $currentStep.map(s => s === steps.business2);
const $business3Step = $currentStep.map(s => s === steps.business3);
const $business4Step = $currentStep.map(s => s === steps.business4);

sample({
  clock: redirectToSignup,
  fn: isLoading => {
    window.location = '/fleet_signup';
  },
});

export const stores = {
  $loginFormValues: loginForm.$values,
  $resetPasswordFormValues: resetPasswordForm.$values,
  $createAccountFormValues: createAccountForm.$values,
  $addAccountTypeFormValues: addAccountTypeForm.$values,
  $addAdditionalInfoBusinessFormValues: addAdditionalInfoBusinessForm.$values,
  $addAdditionalInfoProprietorFormValues:
    addAdditionalInfoProprietorForm.$values,

  $isValidLoginForm,
  $isValidResetPasswordForm,
  $isValidCreateAccountForm,
  $isValidAddAccountTypeForm,
  $isValidAddAdditionalInfoBusinessForm,
  $isValidAddAdditionalInfoProprietorForm,

  $loginFormErrors,
  $resetPasswordFormErrors,
  $createAccountFormErrors,
  $addAccountTypeFormErrors,
  $addAdditionalInfoProprietorFormErrors,
  $addAdditionalInfoBusinessFormErrors,

  $emailValidationMessage,
  $apiMessage,

  $currentStep,
  $currentRole,
  $roles,
  steps,

  $states,
  $professions,
  $payFrequencies,
  $businessTypes,
  $referralSource,
  $entityTypes,
  $industries,

  $loginStep,
  $resetPasswordStep,
  $resetPasswordInfoStep,
  $signupStep,
  $confirmStep,
  $accountTypeStep,
  $proprietor1Step,
  $proprietor2Step,
  $proprietor3Step,
  $proprietor4Step,
  $business1Step,
  $business2Step,
  $business3Step,
  $business4Step,

  $currentEmail,
  $isLoading,
};

export const actions = {
  login,
  loginSubmit,
  loginFx,
  loginFormActions: loginForm,

  resetPassword,
  resetPasswordSubmit,
  resetPasswordFx,
  resetPasswordFormActions: resetPasswordForm,

  createAccount,
  createAccountSubmit,
  createAccountFx,
  createAccountFormActions: createAccountForm,

  addAccountType,
  addAccountTypeSubmit,
  addAccountTypeFx,
  addAccountTypeFormActions: addAccountTypeForm,

  addAdditionalInfoProprietor,
  addAdditionalInfoProprietorSubmit,
  addAdditionalInfoBusiness,
  addAdditionalInfoBusinessSubmit,
  addAdditionalInfoFx,
  addAdditionalInfoProprietorFormActions: addAdditionalInfoProprietorForm,
  addAdditionalInfoBusinessFormActions: addAdditionalInfoBusinessForm,

  resendConfirmation,
  resendResetPassword,
  setData,
  setCurrentStep,
  clearApiMessage,

  redirectToSignup,
};

export const store = combine(stores);
