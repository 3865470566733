import { createEvent, createEffect, combine, sample, forward } from 'effector';
import { stores as orderStores } from 'src/effector/order';
import {
  stores as createAccountStore,
  actions as createAccountActions,
} from 'src/effector/fleet/createAccount';
import isEmpty from 'lodash/isEmpty';

const $steps = combine(
  {
    step: createAccountStore.$currentStep,
    role: createAccountStore.$currentRole,
  },
  ({ step, role }) =>
    [
      {
        name: 'signup',
        title: 'Create Account',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/flag 1.svg',
        desc: 'Enter your data to continue',
        completed: [
          'confirm',
          'accountType',
          'personalInfo',
          'personalLocation',
          'personalProfession',
          'personalSalary',
          'businessInfo',
          'businessLocation',
          'businessDetails',
          'businessCompany',
          'completed',
        ].includes(step),
        inProgress: step === 'signup',
        idle: false,
      },
      {
        name: 'confirm',
        title: 'Check your email',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/envelope.svg',
        desc: 'We’ll send a verification link to your email',
        completed: [
          'accountType',
          'personalInfo',
          'personalLocation',
          'personalProfession',
          'personalSalary',
          'businessInfo',
          'businessLocation',
          'businessDetails',
          'businessCompany',
          'completed',
        ].includes(step),
        inProgress: step === 'confirm',
        idle: ['signup'].includes(step),
      },
      {
        name: 'accountType',
        title: 'Select Account Type',
        icon:
          'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/computer-desktop.svg',
        completed: [
          'personalInfo',
          'personalLocation',
          'personalProfession',
          'personalSalary',
          'businessInfo',
          'businessLocation',
          'businessDetails',
          'businessCompany',
          'completed',
        ].includes(step),
        inProgress: step === 'accountType',
        idle: ['signup', 'confirm'].includes(step),
      },
      {
        name: 'personalInfo',
        title: 'Personal Information',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/user copy.svg',
        completed: [
          'personalLocation',
          'personalProfession',
          'personalSalary',
          'completed',
        ].includes(step),
        inProgress: step === 'personalInfo',
        idle: ['signup', 'confirm', 'accountType'].includes(step),
        shouldShow: () => role?.name === 'proprietor',
      },
      {
        name: 'personalLocation',
        title: 'Location Information',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/map-pin.svg',
        completed: [
          'personalProfession',
          'personalSalary',
          'completed',
        ].includes(step),
        inProgress: step === 'personalLocation',
        idle: ['signup', 'confirm', 'accountType', 'personalInfo'].includes(
          step,
        ),
        shouldShow: () => role?.name === 'proprietor',
      },
      {
        name: 'personalProfession',
        title: 'Profession Information',
        icon:
          'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/building-office-2.svg',
        completed: ['personalSalary', 'completed'].includes(step),
        inProgress: step === 'personalProfession',
        idle: [
          'signup',
          'confirm',
          'accountType',
          'personalInfo',
          'personalLocation',
        ].includes(step),
        shouldShow: () => role?.name === 'proprietor',
      },
      {
        name: 'personalSalary',
        title: 'Salary Information',
        icon:
          'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/credit-card.svg',
        completed: ['completed'].includes(step),
        inProgress: step === 'personalSalary',
        idle: [
          'signup',
          'confirm',
          'accountType',
          'personalInfo',
          'personalLocation',
          'personalProfession',
        ].includes(step),
        shouldShow: () => role?.name === 'proprietor',
      },
      {
        name: 'businessInfo',
        title: 'Businesses Information',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/user copy.svg',
        completed: [
          'businessLocation',
          'businessDetails',
          'businessCompany',
          'completed',
        ].includes(step),
        inProgress: step === 'businessInfo',
        idle: ['signup', 'confirm', 'accountType'].includes(step),
        shouldShow: () => role?.name === 'businesses' || isEmpty(role),
      },
      {
        name: 'businessLocation',
        title: 'Location Information',
        icon: 'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/map-pin.svg',
        completed: ['businessDetails', 'businessCompany', 'completed'].includes(
          step,
        ),
        inProgress: step === 'businessLocation',
        idle: ['signup', 'confirm', 'accountType', 'businessInfo'].includes(
          step,
        ),
        shouldShow: () => role?.name === 'businesses' || isEmpty(role),
      },
      {
        name: 'businessDetails',
        title: 'Businesses Details',
        icon:
          'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/building-office-2.svg',
        completed: ['businessCompany', 'completed'].includes(step),
        inProgress: step === 'businessDetails',
        idle: [
          'signup',
          'confirm',
          'accountType',
          'businessInfo',
          'businessLocation',
        ].includes(step),
        shouldShow: () => role?.name === 'businesses' || isEmpty(role),
      },
      {
        name: 'businessCompany',
        title: 'Company Details',
        icon:
          'https://d19pbi8vkdue6m.cloudfront.net/treadsupply/list-bullet.svg',
        completed: ['completed'].includes(step),
        inProgress: step === 'businessCompany',
        idle: [
          'signup',
          'confirm',
          'accountType',
          'businessInfo',
          'businessLocation',
          'businessDetails',
        ].includes(step),
        shouldShow: () => role?.name === 'businesses' || isEmpty(role),
      },
    ].filter(({ shouldShow = () => true }) => shouldShow()),
);

const $showRegistrationMenu = createAccountStore.$currentStep.map(step =>
  [
    'signup',
    'confirm',
    'accountType',
    'businessInfo',
    'businessLocation',
    'businessDetails',
    'businessCompany',
    'personalInfo',
    'personalLocation',
    'personalProfession',
    'personalSalary',
  ].includes(step),
);

export const stores = {
  $steps,
  $showRegistrationMenu,
};

export const store = combine(stores);
