import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';

const isValid = v => {
  if (isNumber(v)) {
    return v;
  }
  if (isBoolean(v)) {
    return v;
  }
  return !isEmpty(v);
};

export default {
  required: () => ({
    name: 'required',
    validator: value => ({
      isValid: isValid(value),
      errorText: 'This field is required.',
    }),
  }),
  email: () => ({
    name: 'email',
    validator: value => ({
      isValid: /[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/.test(
        value,
      ),
      errorText: 'Please enter a valid email',
    }),
  }),
  minLength: min => ({
    name: 'minLength',
    validator: value => ({
      isValid: value && value.length >= min,
      errorText: `Should be ${min} digits`,
    }),
  }),
  maxLength: max => ({
    name: 'maxLength',
    validator: value => ({
      isValid: value && value.length <= max,
      errorText: `Should be ${max} digits`,
    }),
  }),
  orderNumber: () => ({
    name: 'orderNumber',
    validator: value => ({
      isValid: /[R][0-9]{9}$/.test(value),
      errorText: 'Please enter a valid Order Number',
    }),
  }),
  matchPassword: () => ({
    name: 'matchPassword',
    validator: (value, values) => ({
      isValid: value === values.password,
      errorText: 'Passwords must match',
    }),
  }),
};
