import {
  combine,
  createStore,
  createEvent,
  createEffect,
  sample,
} from 'effector';
import { createForm } from 'effector-forms';
import rules from 'src/effector/forms/rules';
import { getErrors } from 'src/effector/forms/helpers';
import * as api from 'src/logic/api';
const urlParams = new URLSearchParams(window.location.search);
const resetPasswordToken = urlParams.get('reset_password_token') || '';

const resetPassword = createEvent();
const resetPasswordSubmit = createEvent();
const resetPasswordFx = createEffect().use(api.changePassword);

const resetPasswordForm = createForm({
  fields: {
    password: {
      init: '',
      rules: [rules.required(), rules.minLength(6)],
    },
    password_confirmation: {
      init: '',
      rules: [rules.required(), rules.minLength(6), rules.matchPassword()],
    },
    reset_password_token: {
      init: resetPasswordToken,
      rules: [rules.required()],
    },
  },
});

const $resetPasswordFormErrors = getErrors(
  Object.keys(resetPasswordForm.fields),
  resetPasswordForm,
);
const $isValidResetPasswordForm = resetPasswordForm.$isValid.map(
  isValid => isValid,
);
const $resetPasswordDataToSend = resetPasswordForm.$values.map(data => data);

sample({
  clock: sample({
    clock: resetPasswordSubmit,
    target: resetPasswordForm.validate,
  }),
  source: resetPasswordForm.$isValid,
  filter: isValid => isValid,
  target: resetPassword,
});

sample({
  clock: resetPassword,
  source: $resetPasswordDataToSend,
  target: resetPasswordFx,
});

sample({
  clock: resetPasswordFx.done,
  fn: () => {
    window.location = '/';
  },
});

const $isLoading = createStore(false).on(resetPassword, () => true);

export const stores = {
  $resetPasswordFormValues: resetPasswordForm.$values,
  $isValidResetPasswordForm,
  $resetPasswordFormErrors,
  $isLoading,
};

export const actions = {
  resetPassword,
  resetPasswordSubmit,
  resetPasswordFormActions: resetPasswordForm,
};

export const store = combine(stores);
