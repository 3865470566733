import { forward, createDomain, combine, sample } from 'effector';

const drawerDomain = createDomain('fleetDrawer');

const open = drawerDomain.createEvent();
const close = drawerDomain.createEvent();
const setState = drawerDomain.createEvent();

const openFx = drawerDomain.createEffect().use(() => {
  const body = document.querySelector('body');
  body.style.overflow = 'hidden';
});

const closeFx = drawerDomain.createEffect().use(() => {
  setTimeout(
    () =>
      setState({
        type: '',
        state: 'closed',
      }),
    250,
  );
  const body = document.querySelector('body');
  body.style.overflow = 'auto';
});

const $drawer = drawerDomain
  .createStore({
    type: '',
    origin: 'left',
    config: null,
    state: 'closed',
  })
  .on(setState, (_, state) => state)
  .on(open, (_, { type, origin, config }) => ({
    type,
    origin,
    config,
    state: 'opened',
  }));

sample({
  clock: close,
  source: $drawer,
  filter: ({ state }) => state === 'opened',
  fn: store => ({ ...store, state: 'closing' }),
  target: [$drawer, closeFx],
});

const $isOpened = $drawer.map(
  store => store.state === 'opened' || store.state === 'closing',
);

export const actions = {
  close,
  open,
};

export const store = combine($isOpened, $drawer, (isOpened, drawer) => ({
  isOpened,
  type: drawer.type,
  origin: drawer.origin,
  config: drawer.config,
  state: drawer.state,
}));

forward({
  from: open,
  to: openFx,
});
